<template>
    <div>
        <app-header :title="title" :isShowBack="true"></app-header>
        <div class="baseInfo">
            <div class="baseInfo-title">
                出库信息
            </div>
            <div class="form-box">
                <el-form ref="myForm" :model="myForm" :rules="rules" style="margin-left：46px;">
                    <el-form-item label-width="120px" style="display:inline-block;" label="选择门店" prop="groupId">
                        <el-select filterable v-model="myForm.groupId" placeholder="选择要出货至哪个门店"
                            @change="onChangeSelect(myForm.groupId)" clearable>
                            <el-option v-for="it in storeList" :key="it.value" :label="it.label" :value="it.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="120px" style="display: inline-block;" label="出库人" prop="name">
                        <el-input style="width: 320px;display: inline-block;" placeholder="请输入出库人"
                            v-model.trim="myForm.name" clearable>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>

        </div>
        <div class="goods">
            <div class="goods-title">出库商品</div>
            <div class="goods-search">
                <div>商品查询<span style="color:red;">*</span></div>
                <el-autocomplete v-model.trim="itemName" :fetch-suggestions="searchItem" placeholder="商品名称/商品编号"
                    @select="handleSelect"></el-autocomplete>
                <!-- <el-button icon="el-icon-search" type="primary" round @click="onSearch">查询</el-button> -->
            </div>
            <div class="table-box">
                <div style="padding-bottom:8px;width:100px;">商品明细<span style="color:red;">*</span></div>
                <div class="goods-table">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
                        style="width: 100%;height:380px;overflow:auto;" :header-cell-class-name="must"
                        @selection-change="handSelectionChange">
                        <el-table-column type="selection" label="全选" width="55"></el-table-column>
                        <el-table-column label="商品名称" prop="goodsName"></el-table-column>
                        <el-table-column label="商品编号" prop="goodsCode"></el-table-column>
                        <el-table-column :label="isSellOnBusinessFalg ? '经销价（元/件）' : '成本价（元/件）'" :prop="isSellOnBusinessFalg ? 'distributorPrice' : 'goodsCost' "></el-table-column>
                        <el-table-column label="销售价（件）" prop="goodsPrice"></el-table-column>
                        <el-table-column label="剩余库存（件）" prop="remainder"></el-table-column>
                        <el-table-column prop="count" label="出库数量（件）">
                            <template slot-scope="scope">
                                <div class='tempList'>
                                    <el-input v-model.trim="scope.row.count"
                                        @blur="changeTable(scope.$index,scope.row)" placeholder="请输入">
                                    </el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="isSellOnBusinessFalg ? '经销商名称' : '供应商名称'" :prop=" isSellOnBusinessFalg ? 'distributorName' : 'supplierName'"></el-table-column>

                    </el-table>
                    <div class="edit-tableBut-box">
                        <el-button class="edit-tableBut" @click="deleteRow()">删除行</el-button>
                        <!-- <el-button class="edit-tableBut" @click="addRow()">添加行</el-button> -->
                    </div>
                    <div class="goodsSubmit">
                        <el-button type="primary" @click="submit()">提交</el-button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    let reg = /(^[1-9]{1}[0-9]*$)/;
    let priceVali = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,4}$)/;
    export default {
        data() {
            return {
                storeList: [],
                title: '新增出库单',
                itemName: '',
                tableData: [],
                multipleSection: [],
                myForm: {
                    name: '',
                    groupId: '',
                },
                rules: {
                    name: [{
                        required: true,
                        message: "请输入出库人",
                        trigger: ['change', 'blur'],
                    }],
                    groupId: [{
                        required: true,
                        message: "请选择门店",
                        trigger: ['change', 'blur'],
                    }],
                },
                restaurants: [],
                codeList: [],
                isSellOnBusinessFalg: false,
            }
        },
        activated() {
            this.getStoreList();
            this.tableData = [];
            this.myForm.name = '';
            this.myForm.groupId = '';
            this.itemName = '';
            this.codeList = [];
            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields(); //重置表单
                }
            })
            this.isSellOnBusinessFalg = this.isSellOnBusiness()
        },
        deactivated() {
            let pathList = this.$route.path.split('/')
            if (pathList[2] != 'storehouse') {
                this.cache.del('stockType');
            }
        },
        methods: {
            onSearch() {
                console.log('查询')
            },
            handSelectionChange(val) {
                // 选择的行
                this.multipleSection = val;
                console.log('multipleSection:', this.multipleSection)
            },
            changeTable(val, row) {
                this.tableData.forEach(item => {
                    if (item.index == row.index) {
                        item.count = row.count ? Number(row.count) : '';
                    }
                })
            },
            checkFun(row) {
                if (!row.count) {
                    this.$message({
                        message: '商品编号为' + row.goodsCode + '的出库数量不能为空或为0！',
                        type: 'warning',
                    })
                    return
                } else if (row.count && !reg.test(row.count)) {
                    this.$message({
                        message: '商品编号为' + row.goodsCode + '的出库数量不为整数！',
                        type: 'warning',
                    })
                    return
                }
            },
            deleteRow() {
                console.log('删除行')
                if (this.multipleSection < 1) {
                    this.$message({
                        message: '至少选择一项！',
                        type: 'warning',
                    })
                    return
                }
                let selectIdList = [];
                this.multipleSection.forEach(item => {
                    selectIdList.push(item.index);
                })
                let saveList = [];
                this.codeList = [];
                this.tableData.forEach((item, index) => {
                    if (selectIdList.indexOf(item.index) == -1) {
                        saveList.push(item);
                        this.codeList.push(item.goodsCode);
                    }
                })
                this.tableData = saveList;
            },
            addRow() {
                console.log('添加行')
                this.tableData.splice(this.tableData.length, 0, {
                    goodsName: '',
                    goodsCode: '',
                    goodsCost: '',
                    distributorPrice: '',
                    goodsPrice: '',
                    remainder: '',
                    count: '',
                    index: this.tableData.length,
                    supplierName: '',
                    supplierId: '',
                })
            },
            submit() {
                if (this.tableData.length < 1) {
                    this.$message({
                        message: '至少要录入一条商品数据',
                        type: 'warning'
                    })
                    return
                }
                let wrongfulFlag = false;
                this.tableData.find((item, index) => {
                    if (!item.count || !reg.test(item.count)) {
                        this.checkFun(item);
                        return wrongfulFlag = true;
                    } else {
                        if (Number(item.count) > Number(item.remainder)) {
                            this.$message({
                                message: '商品编号为' + item.goodsCode + '的出库数量不能大于剩余库存！',
                                type: 'warning',
                            })
                           return wrongfulFlag = true;
                        }
                    }
                })
                if (wrongfulFlag) {
                    return
                }
                this.$refs['myForm'].validate((valid) => {
                    if (valid) {
                        console.log('提交')
                        let dto = {
                            goodsList: this.tableData,
                            groupId: this.myForm.groupId,
                            name: this.myForm.name,
                        }
                        this.storeList.forEach(item => {
                            if (this.myForm.groupId == item.value) {
                                dto.groupName = item.label;
                            }
                        })
                        this.post('mall-service/v1/warehouse/out/save', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '新增出库单成功！',
                                    type: 'success',
                                })
                                this.$back();
                            }
                        })
                    }
                })

            },
            searchItem(queryString, cb) {
                var restaurants = [];
                if(!this.itemName) {
                    cb(restaurants);
                    return
                }
                restaurants = this.restaurants;
                this.get('mall-service/items/queryItemsByNameOrCode?data=' + this.itemName).then(res => {
                    restaurants = res;
                    if (restaurants.length > 0) {
                        restaurants.forEach(item => {
                            if(item.supplierName) {
                                item.value = item.name + "/" + item.supplierName;
                            }else {
                               item.value = item.name;
                            }
                        })
                    }
                    cb(restaurants);

                })

            },
            createStateFilter(queryString) {
                return (state) => {
                    return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleSelect(item) {
                if (this.codeList.indexOf(item.code) != -1) {
                    this.$message({
                        message: '商品编号重复！',
                        type: 'warning'
                    })
                    this.$refs.multipleTable.setCurrentRow(item);
                    return

                }
                if(this.isSellOnBusinessFalg) {
                    this.tableData.unshift({
                        goodsName: item.name,
                        goodsCode: item.code,
                        goodsCost: item.costPrice ? Number(item.costPrice).toFixed(2) : 0.00,
                        distributorPrice: item.supplyPrice ? Number(item.supplyPrice).toFixed(2) : 0.00,
                        goodsImages: item.pic,
                        goodsCategory: item.category,
                        goodsPrice: item.sellPrice ? Number(item.sellPrice).toFixed(2) : 0.00,
                        remainder: item.remainder ? item.remainder : 0, //剩余库存
                        count: '',
                        type: 2, //1-入库 2-出库
                        index: this.tableData.length,
                        // 经销类的商家一下几个数据需要这么传
                        supplierName: item.costName ? item.costName : '--',
                        supplierId: item.costId ? item.costId : null,
                        distributorName: item.supplierName ? item.supplierName : '--',
                        distributorId: item.supplierId ? item.supplierId : null,
                    })
                }else {
                    this.tableData.unshift({
                        goodsName: item.name,
                        goodsCode: item.code,
                        goodsCost: item.costPrice ? Number(item.costPrice).toFixed(2) : 0.00,
                        goodsImages: item.pic,
                        goodsCategory: item.category,
                        goodsPrice: item.sellPrice ? Number(item.sellPrice).toFixed(2) : 0.00,
                        remainder: item.remainder ? item.remainder : 0, //剩余库存
                        count: '',
                        type: 2, //1-入库 2-出库
                        index: this.tableData.length,
                        supplierName: item.supplierName ? item.supplierName : '--',
                        supplierId: item.supplierId ? item.supplierId : '--',
                    })
                }
                

                this.tableData.forEach(it => {
                    if (this.codeList.indexOf(it.goodsCode) == -1) {
                        this.codeList.push(it.goodsCode);
                    }
                })
                this.itemName = '';
            },
            onChangeSelect(item) {},
            //获取门店列表
            getStoreList() {
                let dto = {
                    pageNum: 1,
                    pageSize: 99999,
                };
                this.post("/mall-service/counter_group/get_list",dto,{
                    isUseResponse: true
                }).then(res => {
                    let storeList = res.data.data.records;
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
                    this.storeList = storeList;
                });
            },
            must(obj) {
                // 库存数量加上 *号
                if (obj.columnIndex == 6) {
                    return 'must'
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    /deep/.el-table th.must>.cell:before {
        content: '*';
        color: red;
    }

    /deep/.el-table::before {
        z-index: 0;
    }

    .form-box {
        margin-left: 46px;
    }

    .baseInfo-title {
        margin-left: 46px;
        padding-bottom: 30px;
    }

    .goods-title {
        margin-left: 46px;
    }

    .goods-search {
        margin: 20px 92px;
        display: flex;

        div {
            line-height: 35px;
            padding-right: 5px;
        }
    }

    .table-box {
        margin-left: 92px;
        // display: flex;
    }

    .goods-table {
        height: 500px;
        // background: #eeeeee;
        margin-left: 68px;
    }

    .edit-tableBut-box {
        text-align: center
    }

    .edit-tableBut {
        margin: 20px 10px;
    }

    .goodsSubmit {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>